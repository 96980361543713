export const getCurrDate = () => {
  const date = new Date()
  // console.log(date.getFullYear()) //当前日期的年 2022
  // console.log(date.getMonth() + 1) //月份+1 由于月份是0-11计算 所以需要 +1
  // console.log(date.getDate()) // 日
  // console.log(date.getDay()) // 星期几  注意：星期日返回的是0
  // console.log(date.getHours()) // 时
  // console.log(date.getMinutes()) // 分
  // console.log(date.getSeconds()) // 秒

  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
}

export const getCurrYear = () => {
  const date = new Date()

  return date.getFullYear()
}
